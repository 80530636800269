import { LinkDataTextEnum, LinkDataPictureEnum } from './../../config/constants';
import { DialogConfirmComponent } from '../../dialog/dialog-confirm/dialog-confirm.component';
import { Template } from '../../model/entity/template';
import { DialogService } from 'app/service/dialog.service';
import { Helper } from 'app/common/helper';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonTable } from 'app/model/entity/commonTable';
import { UpperLimit } from './../../model/entity/upper-limit';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Constant } from 'app/config/constants';
import { ValidationFormService } from '../../service/validation-form.service';

@Component({
  selector: 'app-dialog-upper-limit',
  templateUrl: './dialog-upper-limit.component.html',
  styleUrls: ['./dialog-upper-limit.component.scss']
})
export class DialogUpperLimit implements OnInit {
  /**
   * Constant
   */
  public Constant = Constant;
  /**
   * upper Limit Form
   */
  public upperLimitForm: FormGroup;
  /**
   * upper Limit
   */
  public upperLimit: UpperLimit;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogUpperLimitData,
    private commonTableService: CommonTableService,
    private dialogRef: MatDialogRef<DialogUpperLimit>,
    private formBuilder: FormBuilder,
    private validationFormService: ValidationFormService,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    this.upperLimit = JSON.parse(this.data.commonTable.value);
    this.createForm();
  }

  public createForm(): void {
    this.upperLimitForm = this.formBuilder.group({
      current: [
        this.upperLimit.current,
        [
          this.validationFormService.invalidEmpty,
          Validators.min(Constant.MIN_CURRENT_TIMETABLE),
          Validators.max(Constant.MAX_CURRENT_TIMETABLE)
        ]
      ],
      item: [
        this.upperLimit.item,
        [
          this.validationFormService.invalidEmpty,
          Validators.min(Constant.MIN_COLUMN_TIMETABLE),
          Validators.max(Constant.MAX_COLUMN_TIMETABLE)
        ]
      ]
    });
    this.changeDetectorRef.detectChanges();
  }

  /**
   * save
   * @returns
   */
  public save(): void {
    let upperLimitObject = new UpperLimit(+this.upperLimitForm.value.current, +this.upperLimitForm.value.item);
    if (this.upperLimitForm.invalid) {
      return;
    }
    this.data.commonTable.value = JSON.stringify(upperLimitObject);
    let currentAreas = Helper.getAllAreaTemplate(this.data.templateSelected).filter(
      area =>
        !area.isFix &&
        (area.getArea().attribute == LinkDataPictureEnum.INDEX_WORD ||
          area.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD ||
          area.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE ||
          area.getArea().linkReferenceData == LinkDataTextEnum.OPERATION_INFO)
    );
    let isGreater = currentAreas?.some(
      current =>
        current.referencePositionRow >= upperLimitObject.current ||
        current.referenceColumn >= upperLimitObject.item ||
        (current.getArea().linkReferenceData == LinkDataTextEnum.TIMETABLE && current?.referencePositionColumn > upperLimitObject.item) ||
        ((current.getArea().attribute == LinkDataPictureEnum.INDEX_WORD ||
          current.getArea().linkReferenceData == LinkDataTextEnum.INDEX_WORD) &&
          current?.referencePositionColumn >= upperLimitObject.item)
    );
    if (isGreater) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            title: this.translateService.instant('dialog-confirm.title'),
            text: this.translateService.instant('dialog-upper-limit.msg.greater-than-value'),
            button1: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-1'),
            button2: this.translateService.instant('dialog-confirm.timetable-operation-manager.button-2')
          }
        },
        result => {
          if (result) {
            this.commonTableService.save(this.data.commonTable).subscribe(
              () => {
                this.dialogRef.close([true, this.data.commonTable.value]);
              },
              error => Helper.handleError(error, this.translateService, this.dialogService)
            );
          } else {
            this.dialogRef.close();
          }
        }
      );
    } else {
      this.commonTableService.save(this.data.commonTable).subscribe(
        () => {
          this.dialogRef.close([false, this.data.commonTable.value]);
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    }
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }
}
export interface DialogUpperLimitData {
  commonTable: CommonTable;
  templateSelected: Template;
}
