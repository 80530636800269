import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { AppDesignDTO } from 'app/model/entity/ticket-editor/dto/app-design-DTO';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { DialogService } from 'app/service/dialog.service';
import { MediaService } from 'app/service/media.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';

@Component({
  selector: 'dialog-delivery-application-ticket-editor',
  templateUrl: './dialog-delivery-application-ticket-editor.component.html',
  styleUrls: ['./dialog-delivery-application-ticket-editor.component.scss']
})
export class DialogDeliveryApplicationTicketEditorComponent implements OnInit {
  public appdesign: AppDesignDTO;
  public application: ApplicationDTO;
  public languageKey: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private ticketService: TicketEditorService,
    private dialogRef: MatDialogRef<DialogDeliveryApplicationTicketEditorComponent>,
    private mediaService: MediaService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.application = this.data.appSelected;
    this.appdesign = this.data.appDesignSelected;
    this.languageKey = this.data.languageKey;
  }

  async delivery(): Promise<void> {
    const detail = this.appdesign.appDetailDTO;
    if (detail.topPhoto && !detail.imagePath) {
      const res1 = await new Promise<any>((resolve, reject) => {
        this.mediaService.getFileFromUrl(detail.topPhotoUrl).subscribe(resolve, reject);
      });
      const file1 = new File([res1.body], 'image.png', {
        type: 'image/jpeg'
      });
      let image$ = await Helper.convertFileToBase64(file1);
      let payload = {
        image: image$,
        uploadImageType: 'app_settings'
      };
      const imagePath = await new Promise<any>((resolve, reject) => {
        this.ticketService.postImage(this.data.informationAccount, payload).subscribe(resolve, reject);
      });
      this.callAPIDelivery(detail, imagePath?.filePath);
    } else {
      this.callAPIDelivery(detail);
    }
  }
  /**
   *
   * @param detail
   */
  private callAPIDelivery(detail, image?) {
    let payload: any = {
      appName: this.application.name ?? null,
      companyId: +this.data.informationAccount.compnanyid ?? null,
      shortName: detail.shortNameOfTheApp ?? null,
      imagePath: image ? image : detail.imagePath ?? null,
      mainColor: detail.mainColor.toLocaleUpperCase() ?? null,
      subColor: detail.subColor.toLocaleUpperCase() ?? null,
      backgroundColor: detail.backgroundColor.toLocaleUpperCase() ?? null,
      textColor: detail.textColor.toLocaleUpperCase() ?? null,
      borderColor: detail.borderColor.toLocaleUpperCase() ?? null,
      inactiveColor: detail.inActiveColor.toLocaleUpperCase() ?? null,
      languages: this.application.supportedLanguageTranslation ?? null,
      appUrl: this.application.appUrl ? this.application.appUrl : null,
      appHomepageUrl: this.application.appHomepageUrl ? this.application.appHomepageUrl : null,
      userPoolId: this.application.userPoolId
    };
    if (this.data.appSelected.appId) {
      payload.appId = this.application.appId;
      this.ticketService.putApplication(payload, this.data.informationAccount).subscribe(data => {
        if (image) {
          this.appdesign.appDetailDTO.imagePath = image;
        }
        this.appdesign.appIdAPI = data.appId;
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('ticket-editor.tilte-delivery-success'),
            text: this.translateService.instant('ticket-editor.delivery-success')
          }
        });
        this.dialogRef.close(this.appdesign);
      });
    } else {
      this.ticketService.postApplication(payload, this.data.informationAccount).subscribe(data => {
        if (image) {
          this.appdesign.appDetailDTO.imagePath = image;
        }
        this.appdesign.appIdAPI = data.appId;
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('ticket-editor.tilte-delivery-success'),
            text: this.translateService.instant('ticket-editor.delivery-success')
          }
        });
        this.dialogRef.close(this.appdesign);
      });
    }
  }
}

export interface DialogData {
  appSelected: ApplicationDTO;
  appDesignSelected: AppDesignDTO;
  informationAccount: any;
  languageKey: string;
}
