import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageErrorImportFile } from 'app/model/entity/announcement/message-error-import-file';
import { MessageErrorTabTranslation } from 'app/model/entity/announcement/message-error-tab-translation';

@Component({
  selector: 'app-dialog-message',
  templateUrl: './dialog-message.component.html',
  styleUrls: ['./dialog-message.component.scss']
})
/**
 * Dialog Message/Error
 */
export class DialogMessageComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DialogMessageComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {}
}

/**
 * Dialog data
 */
export interface DialogData {
  title: string;
  text: string;
  textHasLineBreaks: string;
  texts: Array<string>;
  validateImport?: MessageErrorImportFile;
  validateTabTranslation?: MessageErrorTabTranslation;
  textWithHashtags?: Array<string>;
}
