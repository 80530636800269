import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant } from 'app/config/constants';
import { ApplicationDesign } from 'app/model/entity/ticket-editor/application-design';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { DialogService } from 'app/service/dialog.service';
import { ApplicationService } from 'app/service/ticket-editor/application.service';
import _ from 'lodash';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
@Component({
  selector: 'dialog-duplicate-application-design',
  templateUrl: './dialog-duplicate-application-design.component.html',
  styleUrls: ['./dialog-duplicate-application-design.component.scss']
})
export class DialogDuplicateApplicationDesignComponent implements OnInit {
  listApp: Array<ApplicationDTO> = new Array<ApplicationDTO>(); // list Application
  appIdModel: Number; // App id by data element
  designNameModel: string; // Design name by data element
  @ViewChild('designName') designNameElementRef: ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ApplicationDesign,
    private dialogRef: MatDialogRef<DialogDuplicateApplicationDesignComponent>,
    private applicationService: ApplicationService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
    this.designNameModel = data.designName;
  }

  ngOnInit(): void {
    this.applicationService.findAllApplication().subscribe(res => {
      this.listApp = Helper.convertResApplication(res);
      if (this.listApp.length > 0) {
        this.appIdModel = this.listApp[0].id;
      }
    });
  }

  /**
   * Duplicate
   */
  duplicate(): void {
    if (!this.validateDesignName(this.designNameModel)) {
      return;
    }
    const result = _.cloneDeep(this.data);
    result.appId = this.appIdModel;
    result.designName = this.designNameModel;
    result.appIdAPI = undefined;
    result.isSynchronizedDelivery = false;
    result.id = undefined;
    this.dialogRef.close(result);
  }

  /**
   * validate designName when add new/ edit
   * @param designName
   */
  validateDesignName(designName: string): boolean {
    // validate app name
    if (designName.trim().length < Constant.MIN_NO_LENGTH || designName.trim().length > Constant.MAXIMUM_TEXT_LENGTH) {
      this.designNameElementRef.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.msg.validate-app-design-name')
        }
      });
      return false;
    }

    return true;
  }

  /**
   * Select Application
   */
  selectApplication(appIdModel: Number): void {
    this.appIdModel = Number(appIdModel);
  }

  changeDisplay(value): String {
    if (value.length > 11) {
      value = value.substring(0, 11) + '...';
    }
    return value;
  }
}
